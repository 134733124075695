import React from 'react'
import styled from 'styled-components'

import { positive, positiveLight, negative, specialLight } from '../theme'
import { Icon } from './Icon'

type ButtonVariant = 'accept' | 'decline'

interface ButtonProps {
  variant: ButtonVariant
}
interface IconButtonProps {
  variant: ButtonVariant
  onClick: () => void
}

const Button = styled.button<ButtonProps>`
  outline: none;
  border: none;

  height: 72px;
  width: 72px;
  margin: 0 8px;

  border: 4px solid
    ${(props) => (props.variant === 'accept' ? positiveLight : specialLight)};
  border-radius: 100%;
  cursor: pointer;

  color: #ffffff;

  transition: border-color 100ms linear, transform 100ms linear;

  &:hover {
    border-color: transparent;
    transform: scale(1.1);

    svg {
      transform: scale(0.909);
    }
  }
`
export const Surface = styled.div<ButtonProps>`
  display: flex;
  height: 64px;
  width: 64px;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: ${(props) =>
    props.variant === 'accept' ? positive : negative};
  border-radius: 100%;

  svg {
    transition: transform 100ms linear;
  }
`

export const IconButton = ({ variant, onClick }: IconButtonProps) => (
  <Button variant={variant} onClick={onClick}>
    <Surface variant={variant}>
      <Icon variant={variant} />
    </Surface>
  </Button>
)
