import React from 'react'
import styled from 'styled-components'

type ButtonSecondaryProps = {
  onClick: () => void
  children: React.ReactNode
  light?: boolean
}

export const ButtonSecondary = ({
  children,
  onClick,
  light,
}: ButtonSecondaryProps) => {
  return (
    <Link
      href="#"
      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        onClick()
      }}
      light={light}
    >
      {children}
    </Link>
  )
}

type LinkProps = {
  light?: boolean
}

const Link = styled.a<LinkProps>`
  border-radius: 10px;
  padding: 6px 12px;
  background: ${(props) =>
    props.light ? '#F8F7F3' : 'rgba(255, 255, 255, 0.2)'};
  transition: background-color 0.2s;
  text-decoration: none;
  color: #2c2c2c;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  gap: 8px;
  &:hover {
    background-color: ${(props) => (props.light ? '#E7E4DB' : '#fff')};
  }
  img {
    height: 20px;
  }
  span {
    line-height: 20px;
    white-space: nowrap;
    @media only screen and (max-width: 500px) {
      display: none;
    }
  }
`
