import React from 'react'
import { GameEvent, GameState } from '../model'

interface GameStatePresenterProps {
  gameState: GameState
  event: GameEvent
  eventCount: number
}

export const GameStatePresenter = ({
  gameState,
  event,
  eventCount,
}: GameStatePresenterProps) => {
  return <></>
}
