/*eslint-disable*/ module.exports = {
  messages: {
    'language.heading': 'Klimaspiel',
    'language.start': 'Spiel starten',
    'scenario.error.occurred': 'An unexpected error occured.',
    'event.end-game': 'Spiel beenden',
    'event.info.ok': 'Ok',
    'event.next-event': 'Weiter',
    'event.affectedRoles.intro': 'This has special consequences for the',
    'manual.parameters.and.interests.title': 'Parameter',
    'ranging.title.gameState.lost':
      'Too bad, the global score is below zero! The council has made some unsustainable decisions and has failed to keep the coffee sector in balance.',
    'ranging.title.gameState.playing': 'Your current scores',
    'ranging.title.gameState.won':
      'Congratulations, the global score is positive! The council has made sustainable decisions and has managed to keep the coffee sector in balance.',
    'ranking.back_to_game': 'Weiterspielen',
    'ranking.intro.title': 'Rangliste',
    'head.title': 'Klimaspiel',
    'head.meta.desc':
      'Game changers for change agents – UN-Nachhaltigkeitsziele im Dialog',
    'manual.intro.title': 'Rollen',
    'manual.title': 'Legende',
    'ranking.intermediate-result': 'Zwischenstand',
    'ranking.points-short': 'Pt',
    'ranking.result': 'Rangliste',
    'ranking.show-result': 'Weiter zur Gesetzesvorlage',
    'result.title': 'Gesetzesvorlage',
    'result.print': 'Drucken',
    'result.restart': 'Nochmals spielen',
    'event.headline-title': 'Schlagzeile',
    'event.question-title':
      '{{sector_name}} — Massnahme {{event_number}}/{{event_count}}',
    'event.decision-title':
      '{{sector_name}} — Massnahme {{event_number}}/{{event_count}} — Auswirkung',
    'language.developed_by': 'Dieses Spiel wurde entwickelt von:',
    'language.financed_by': 'Mit finanzieller Unterstützung durch:',
    'language.link_uni_be': 'https://www.cde.unibe.ch/index_ger.html',
    'language.link_zeilenwerk': 'https://www.zeilenwerk.ch/',
    'language.link_fnnf': 'https://www.snf.ch/de/',
    'event.skip': 'Frage überspringen',
    'header.game_info_link': 'http://www.klimaspiel.unibe.ch/',
    'header.game_info': 'Infos zum Spiel',
    'header.project_info_link':
      'https://www.cde.unibe.ch/forschung/projekte/game_changers_for_change_agents_un_nachhaltigkeitsziele_im_dialog_chacha/index_ger.html',
    'header.project_info': 'Infos zum Projekt',
  },
}
