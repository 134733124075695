import { Role } from '../model/Role'
import { getTranslated } from './helpers'
import { INTERESTS } from './interests'

const rolesData = require('../static/roles.json')

export const ROLES: Role[] = rolesData
  .map((r: { [key: string]: string }): Role => {
    // roles interests - weight is 0 if not set
    const getInterests = () => {
      const roleInterests = r.interests.split(',').map((i) => i.trim())
      const roleInterestsWeights = r.interestWeight
        .split(',')
        .map((i) => i.trim())

      let interestWeights = {}

      INTERESTS.forEach((interest) => {
        let weight = 0
        const index = roleInterests.findIndex((ri) => ri === interest.id)
        if (index !== -1 && roleInterestsWeights[index]) {
          weight = parseInt(roleInterestsWeights[index], 10)
        }
        interestWeights = {
          ...interestWeights,
          ...{
            [interest.id]: weight,
          },
        }
      })

      return interestWeights
    }

    const getInterestIcons = () => {
      const roleInterests = r.interests.split(',').map((i) => i.trim())
      const icons: string[] = []

      roleInterests.forEach((id) => {
        const interest = INTERESTS.find((i) => i.id === id)
        if (interest) {
          icons.push(interest.icon)
        }
      })

      return icons
    }

    return {
      id: r.id,
      name: getTranslated(r, 'name'),
      desc: getTranslated(r, 'desc'),
      avatar: r.avatar,
      avatarSmall: r.avatarSmall,
      jobDesc: getTranslated(r, 'jobDesc'),
      interest: getInterests(),
      interestIcons: getInterestIcons(),
      sort: parseInt(r.sort),
    }
  })
  .sort(function (a: Role, b: Role) {
    return a.sort - b.sort
  })
