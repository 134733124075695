import React from 'react'
import styled from 'styled-components'

import * as theme from '../theme'
import { ButtonFontStyle } from './Typography'

type ButtonProps = {
  children: React.ReactNode
  size?: 'small'
  onClick: () => void
  arrow?: boolean
}

export const Button = ({ children, size, onClick, arrow }: ButtonProps) => {
  return (
    <ButtonWrap
      size={size}
      href="#"
      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        onClick()
      }}
    >
      <span>{children}</span>
      {arrow && <img src="/images/arrow.svg" alt="arrow" />}
    </ButtonWrap>
  )
}

type ButtonWrapProps = {
  size?: 'small'
}

export const ButtonWrap = styled.a<ButtonWrapProps>`
  display: inline-flex;
  align-items: center;
  gap: 10px;

  box-sizing: border-box;

  ${ButtonFontStyle}
  font-size: ${(props) => (props.size === 'small' ? '16' : '18')}px;
  text-align: ${(props) => (props.size === 'small' ? 'left' : 'center')};
  text-decoration: none;
  letter-spacing: 0.02em;
  color: #2c2c2c;

  width: ${(props) => (props.size === 'small' ? '100%' : 'auto')};

  margin-top: 10px;
  padding: ${(props) => (props.size === 'small' ? '15px 20px' : '20px 40px')};

  background: ${theme.color.button.background};

  border: 3px solid ${theme.color.button.border};
  border-radius: ${(props) => (props.size === 'small' ? 20 : 100)}px;

  box-shadow: 0px 0px 0px ${theme.color.button.border};
  transition: box-shadow 0.2s linear;
  &:hover {
    box-shadow: 2px 2px 0px ${theme.color.button.border};
  }

  &:disabled {
    cursor: default;
  }

  span {
    flex: 1;
  }

  img {
    flex: 0;
  }

  @media only screen and (max-width: 500px) {
    padding: ${(props) => (props.size === 'small' ? '15px 20px' : '10px 20px')};
  }
`

type CheckBoxButtonVariants = 'selected' | 'inactive'

interface CheckBoxButtonProps {
  children: React.ReactNode
  onClick: () => void
  variant?: CheckBoxButtonVariants
}

export const CheckBoxButton = ({
  children,
  onClick,
  variant,
}: CheckBoxButtonProps) => {
  return (
    <CheckBoxButtonWrap
      onClick={() => {
        onClick()
      }}
      variant={variant}
    >
      {variant === 'selected' ? (
        <img src="/images/checkbox-checked.svg" alt="checkbox checked" />
      ) : (
        <>
          {variant === 'inactive' ? (
            <img src="/images/checkbox-inactive.svg" alt="checkbox inactive" />
          ) : (
            <img src="/images/checkbox.svg" alt="checkbox" />
          )}
        </>
      )}
      <CheckBoxButtonContent>{children}</CheckBoxButtonContent>
    </CheckBoxButtonWrap>
  )
}

export const CheckBoxButtonWrap = styled.button<{
  variant?: CheckBoxButtonVariants
}>`
  ${ButtonFontStyle()}
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.02em;
  text-align: left;

  display: flex;
  align-items: center;
  width: 100%;

  margin-top: 10px;
  padding: 15px 20px;

  background: ${theme.color.button.background};

  border: 3px solid ${theme.color.button.border};
  border-width: 3px;
  border-color: ${(props) =>
    props.variant === 'inactive' || props.variant === 'selected'
      ? 'transparent'
      : theme.color.button.border};
  box-shadow: ${(props) =>
    props.variant === 'inactive' || props.variant === 'selected'
      ? 'none'
      : `0px 0px 0px ${theme.color.button.border}`};

  transition: box-shadow 0.2s linear;
  &:hover {
    box-shadow: ${(props) =>
      props.variant === 'inactive' || props.variant === 'selected'
        ? 'none'
        : `2px 2px 0px ${theme.color.button.border}`};
  }

  border-radius: 20px;

  cursor: ${(props) =>
    props.variant === 'inactive' || props.variant === 'selected'
      ? 'initial'
      : 'pointer'};
  outline: none;
  -webkit-appearance: none;

  margin-top: 10px;
`

export const CheckBoxButtonContent = styled.div`
  padding-left: 20px;
  text-align: left;
`
