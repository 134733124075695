import React from 'react'
import styled from 'styled-components'

export const DotScore = ({ score }: { score: number }) => {
  const displayScoreDots = () => {
    const scoreDots = []
    for (let i = 0; i < 5; i++) {
      scoreDots.push(
        <ScoreDotWrap key={i}>
          <ScoreDot />
        </ScoreDotWrap>
      )
    }
    return scoreDots
  }

  return (
    <ScoreWrap>
      <ScoreProgress>
        <ScoreProgressBar width={`${score}%`} />
      </ScoreProgress>
      <ScoreDots>{displayScoreDots()}</ScoreDots>
    </ScoreWrap>
  )
}

const ScoreWrap = styled.div``

const ScoreProgress = styled.div`
  width: 130px;
  height: 24px;
  background: #ebebf0;
`

// add "transition: width .2s" here if you want to animate it
const ScoreProgressBar = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  height: 100%;
  background-color: #f17765;
`

const ScoreDots = styled.div`
  display: inline-flex;
  margin-top: -24px;
  position: absolute;
`

const ScoreDotWrap = styled.div`
  display: inline-block;
  width: 26px;
  height: 26px;
  overflow: hidden;
`

const ScoreDot = styled.div`
  width: 24px;
  height: 24px;
  box-shadow: 0 0 0 24px #fff;
  border-radius: 50%;
`
