import React, { useContext } from 'react'
import { RouteComponentProps } from '@reach/router'

import { ScenariosContext, GameStateContext } from '../contexts'
import {
  EventPresenter,
  GameStatePresenter,
  RankingPresenter,
} from '../presenters'
import { ManualPresenter } from '../presenters/gameScreens/ManualPresenter'
import { Trans } from '@lingui/macro'
import { IntroPresenter } from '../presenters/gameScreens/IntroPresenter'
import { GameScreen } from '../model'
import { AppLayout } from '../facets'
import { SectorPresenter } from '../presenters/gameScreens/SectorPresenter'
import { ResultPresenter } from '../presenters/gameScreens/ResultPresenter'

type GameScreenMap = {
  [screen in GameScreen]: () => JSX.Element | null
}

export const Scenario = ({ id }: RouteComponentProps<{ id: string }>) => {
  const { scenario } = useContext(ScenariosContext)
  const { gameState } = useContext(GameStateContext)
  const { screen } = gameState

  /*useEffect(() => {
    reloadScenario()
  }, [])*/

  const event = scenario?.events.find(
    (event) => event.id === gameState.currentEventId
  )

  if (!event)
    return (
      <AppLayout>
        <Trans id="scenario.error.occurred">
          Es ist ein Fehler aufgetreten!
        </Trans>
      </AppLayout>
    )
  const eventCount = scenario ? scenario.events.length : 0

  const gameScreens: GameScreenMap = {
    manual: ManualPresenter,
    intro: IntroPresenter,
    event: EventPresenter,
    ranking: RankingPresenter,
    sector: SectorPresenter,
    result: ResultPresenter,
  }
  const GameScreen = gameScreens[screen]

  return (
    <>
      <GameStatePresenter {...{ gameState, event, eventCount }} />
      <GameScreen />
    </>
  )
}
