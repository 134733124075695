import { DecisionOption, GameEvent, GameState, Role } from '../model'
import { LOCAL_GAME_STATE } from '../storage'
import {
  getFutureEvents,
  INITIAL_GAME_STATE,
  proceedToNextEvent,
  processDecision,
  setRoles,
  skipEvent,
} from '../util.ts/gameState'
import { ROLES } from '../util.ts/roles'

type ActionShowManual = {
  type: 'showManual'
}
type ActionShowRanking = {
  type: 'showRanking'
}
type ActionCloseRanking = {
  type: 'closeRanking'
}
type ActionSetRoles = {
  type: 'setRoles'
  roles: Role[]
}
type ActionStart = { type: 'start' }
type ActionResolveEvent = {
  type: 'resolveEvent'
  event: GameEvent
  decision: DecisionOption
}
type ActionNextEvent = {
  type: 'nextEvent'
}
type ActionEndGame = {
  type: 'endGame'
}
type ActionShowEventQuestion = {
  type: 'showEventQuestion'
}
type ActionRestart = { type: 'restart' }
type ActionCloseSection = { type: 'closeSection' }
type ActionLoadGameState = { type: 'loadGameState' }
type ActionSaveGameState = { type: 'saveGameState' }
type ActionResult = { type: 'result' }
type ActionSkipEvent = { type: 'skipEvent' }

export type GameStateActionI =
  | ActionShowManual
  | ActionSetRoles
  | ActionResolveEvent
  | ActionNextEvent
  | ActionRestart
  | ActionStart
  | ActionEndGame
  | ActionShowRanking
  | ActionCloseRanking
  | ActionShowEventQuestion
  | ActionCloseSection
  | ActionLoadGameState
  | ActionSaveGameState
  | ActionResult
  | ActionSkipEvent

export type GameStateReducer = (
  prevState: GameState,
  action: GameStateActionI
) => GameState

export const gameStateReducer: GameStateReducer = (
  state: GameState,
  action: GameStateActionI
): GameState => {
  switch (action.type) {
    case 'showManual':
      return {
        ...state,
        ...setRoles(state, ROLES), // All roles are playing - no selection of roles
        previousScreen:
          state.screen === 'ranking' ? state.previousScreen : state.screen,
        screen: 'manual',
      }
    case 'start':
      return {
        ...INITIAL_GAME_STATE,
        screen: 'sector',
      }
    case 'resolveEvent':
      return {
        ...processDecision(state, action.event, action.decision),
        screen: 'event',
      }
    case 'nextEvent': {
      const futureEvents = getFutureEvents(state)
      const hasFutureEvents = futureEvents.length > 0

      if (!hasFutureEvents) {
        return {
          ...state,
          gameState: state.globalScore <= 0 ? 'lost' : 'won',
          screen: 'ranking',
        }
      }

      const nextState = proceedToNextEvent(state)
      const eventIsInAnotherSection =
        nextState.sectorId !== nextState.previousSectorId

      return {
        ...nextState,
        currentEventDisplayInfo: true,
        screen: eventIsInAnotherSection ? 'sector' : 'event',
      }
    }
    case 'endGame':
      return {
        ...state,
        gameState: state.globalScore <= 0 ? 'lost' : 'won',
        screen: 'ranking',
      }
    case 'restart':
      return INITIAL_GAME_STATE
    case 'showRanking':
      return {
        ...state,
        previousScreen: state.screen,
        screen: 'ranking',
      }
    case 'closeRanking':
      return {
        ...state,
        screen: state.previousScreen,
      }
    case 'showEventQuestion':
      return {
        ...state,
        currentEventDisplayInfo: false,
      }
    case 'closeSection':
      return {
        ...state,
        screen: 'event',
      }
    case 'loadGameState': {
      try {
        const s = JSON.parse(localStorage.getItem(LOCAL_GAME_STATE) || '')
        return s
      } catch (e) {
        return INITIAL_GAME_STATE
      }
    }
    case 'saveGameState': {
      localStorage.setItem(LOCAL_GAME_STATE, JSON.stringify(state))
      return state
    }
    case 'result':
      return {
        ...state,
        screen: 'result',
      }
    case 'skipEvent': {
      const nextState = skipEvent(state)

      if (!nextState) {
        return {
          ...state,
          gameState: state.globalScore <= 0 ? 'lost' : 'won',
          screen: 'ranking',
        }
      }

      const eventIsInAnotherSection =
        nextState.sectorId !== nextState.previousSectorId

      return {
        ...nextState,
        currentEventDisplayInfo: true,
        screen: eventIsInAnotherSection ? 'sector' : 'event',
      }
    }
    default:
      return state
  }
}
