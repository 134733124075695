import styled from 'styled-components'

import { textDark } from '../theme'

export const Paragraph = styled.p`
  max-width: 700px;
  margin: 0 auto 16px auto;
  padding: 0 16px;

  color: ${textDark};

  & + & {
    margin-top: 8px;
  }
`
