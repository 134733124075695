import React, { useState, useEffect } from 'react'
import { ReactNode } from 'react-markdown'
import { Scenario } from '../model'
import { INITIAL_EVENTS } from '../util.ts/gameEvents'
import { LOCAL_SCENARIO } from '../storage'
import { SECTORS } from '../util.ts/secotrs'

const INITIAL_SCENARIO = {
  id: 1,
  name: 'scenarioName',
  events: INITIAL_EVENTS,
  sectors: SECTORS,
}

const ScenariosContext = React.createContext<{
  scenario: Scenario
  reload: () => void
}>({ scenario: INITIAL_SCENARIO, reload: () => {} })

function ScenariosContextProvider({ children }: { children: ReactNode }) {
  const [scenario, setScenario] = useState<null | Scenario>(null)

  useEffect(() => {
    try {
      const s = JSON.parse(localStorage.getItem(LOCAL_SCENARIO) || '')
      setScenario(s)
    } catch (e) {
      setScenario(INITIAL_SCENARIO)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem(LOCAL_SCENARIO, JSON.stringify(scenario))
  }, [scenario])

  const reload = () => {
    setScenario(INITIAL_SCENARIO)
  }

  if (!scenario) {
    return null
  }

  return (
    <ScenariosContext.Provider value={{ scenario, reload }}>
      {children}
    </ScenariosContext.Provider>
  )
}

export { ScenariosContextProvider, ScenariosContext }
