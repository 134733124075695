import React, { useContext } from 'react'
import styled from 'styled-components'
import { i18n } from '@lingui/core'

import { GameStateContext, ScenariosContext } from '../contexts'
import { ButtonSecondary } from '../facets/ButtonSecondary'
import { Color } from '../model/Color'
import { Trans } from '@lingui/macro'

export type GameHeaderVariants = {
  bgColor?: Color
  headerVariant?:
    | 'inGame'
    | 'rankingOpen'
    | 'manualOpen'
    | 'resultOpen'
    | 'intro'
}

interface GameHeaderPresenterProps extends GameHeaderVariants {}

export const GameHeaderPresenter = ({
  bgColor = '#fff',
  headerVariant,
}: GameHeaderPresenterProps) => {
  const { dispatch, event, gameState } = useContext(GameStateContext)
  const { scenario } = useContext(ScenariosContext)
  const { events } = scenario

  const { screen } = gameState
  const decision = gameState.pastDecisions.find(
    (d) => d.eventId === event.id
  )?.decision

  const handleOpenRankingClick = () => {
    dispatch({ type: 'showRanking' })
  }
  const handleCloseRankingClick = () => {
    dispatch({ type: 'closeRanking' })
  }
  const handleOpenManualClick = () => {
    dispatch({ type: 'showManual' })
  }
  const handleCloseManualClick = () => {
    dispatch({ type: 'closeRanking' })
  }
  const handleEndGameClick = () => {
    dispatch({ type: 'endGame' })
  }
  const handleRestart = () => {
    dispatch({ type: 'restart' })
  }
  const handlePrint = () => {
    window && window.print()
  }

  const displayInGameHeader = () => {
    const eventsInSector = events.filter(
      (e) =>
        e.sector.id === event.sector.id && !e.noDecision && e.id !== event.id
    )

    const eventsInSectorAnswered = eventsInSector.filter(
      (e) =>
        gameState.pastDecisions.find((pe) => pe.eventId === e.id) ||
        gameState.skippedEvents.find((se) => se.id === e.id)
    )

    let headline = ''
    if (event.noDecision && gameState.screen !== 'sector') {
      headline = i18n._({ id: 'event.headline-title' })
    }

    if (!event.noDecision && screen === 'event') {
      headline = i18n._({ id: 'event.question-title' })
      if (decision) {
        headline = i18n._({ id: 'event.decision-title' })
      }
    }

    headline = headline
      .replace('{{sector_name}}', event.sector.name[i18n.locale as 'de'])
      .replace('{{event_number}}', `${eventsInSectorAnswered.length + 1}`)
      .replace('{{event_count}}', `${eventsInSector.length + 1}`)

    return (
      <Header bgColor={bgColor}>
        <HeaderInner>
          <Left>{headline}</Left>
          <Right>
            <ButtonSecondary onClick={handleOpenRankingClick}>
              <img src="/images/ranking.svg" alt="ranking" />
              <span>
                <Trans id="ranking.intro.title">Rangliste</Trans>
              </span>
            </ButtonSecondary>
            <ButtonSecondary onClick={handleOpenManualClick}>
              <img src="/images/manual.svg" alt="manual" />
              <span>
                <Trans id="manual.title">Legende</Trans>
              </span>
            </ButtonSecondary>
            <ButtonSecondary onClick={handleEndGameClick}>
              <img src="/images/end-game.svg" alt="end game" />
              <span>
                <Trans id="event.end-game">Spiel beenden</Trans>
              </span>
            </ButtonSecondary>
          </Right>
        </HeaderInner>
      </Header>
    )
  }

  const displayRankingHeader = () => {
    return (
      <Header bgColor="#fff" borderColor="#E7E4DB">
        <HeaderInner>
          <Left>
            {gameState.gameState === 'playing' ? (
              <Trans id="ranking.intermediate-result">Zwischenstand</Trans>
            ) : (
              <Trans id="ranking.result">Rangliste</Trans>
            )}
          </Left>
          <Right>
            {gameState.gameState === 'playing' && (
              <>
                <ButtonSecondary onClick={handleOpenManualClick} light>
                  <img src="/images/manual.svg" alt="manual" />
                  <span>
                    <Trans id="manual.title">Legende</Trans>
                  </span>
                </ButtonSecondary>
                <ButtonSecondary onClick={handleEndGameClick} light>
                  <img src="/images/end-game.svg" alt="end game" />
                  <span>
                    <Trans id="event.end-game">Spiel beenden</Trans>
                  </span>
                </ButtonSecondary>
              </>
            )}
            {gameState.gameState === 'playing' && (
              <CloseWrap>
                <ButtonSecondary onClick={handleCloseRankingClick} light>
                  <img src="/images/close.svg" alt="end game" />
                </ButtonSecondary>
              </CloseWrap>
            )}
          </Right>
        </HeaderInner>
      </Header>
    )
  }

  const displayManualHeader = () => {
    return (
      <Header bgColor="transparent" noBorder>
        <HeaderInner>
          <Left />
          <Right>
            <CloseWrap>
              <ButtonSecondary onClick={handleCloseManualClick} light>
                <img src="/images/close.svg" alt="end game" />
              </ButtonSecondary>
            </CloseWrap>
          </Right>
        </HeaderInner>
      </Header>
    )
  }

  const displayResultOpenHeader = () => {
    return (
      <Header bgColor="#fff" borderColor="#E7E4DB">
        <HeaderInner>
          <Left>
            <Trans id="result.title">Gesetzesvorlage</Trans>
          </Left>
          <Right>
            <ButtonSecondary onClick={handlePrint} light>
              <img src="/images/ranking.svg" alt="ranking" />
              <span>
                <Trans id="result.print">Drucken</Trans>
              </span>
            </ButtonSecondary>
            <ButtonSecondary onClick={handleOpenManualClick} light>
              <img src="/images/manual.svg" alt="manual" />
              <span>
                <Trans id="manual.title">Legende</Trans>
              </span>
            </ButtonSecondary>
            <ButtonSecondary onClick={handleRestart} light>
              <img src="/images/replay.svg" alt="replay" />
              <span>
                <Trans id="result.restart">Nochmals spielen</Trans>
              </span>
            </ButtonSecondary>
          </Right>
        </HeaderInner>
      </Header>
    )
  }

  const displayIntroHeader = () => {
    return (
      <Header bgColor="transparent" noBorder static>
        <HeaderInner>
          <Left />
          <Right>
            <ProjectLink
              href={i18n._({ id: 'header.game_info_link' })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans id="header.game_info">Infos zum Spiel</Trans>
            </ProjectLink>
            <ProjectLink
              href={i18n._({ id: 'header.project_info_link' })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans id="header.project_info">Infos zum Projekt</Trans>
            </ProjectLink>
          </Right>
        </HeaderInner>
      </Header>
    )
  }

  switch (headerVariant) {
    case 'inGame':
      return displayInGameHeader()

    case 'rankingOpen':
      return displayRankingHeader()

    case 'manualOpen':
      return displayManualHeader()

    case 'resultOpen':
      return displayResultOpenHeader()

    case 'intro':
      return displayIntroHeader()

    default:
      return null
  }
}

type HeaderProps = {
  bgColor: string
  borderColor?: string
  noBorder?: boolean
  static?: boolean
}

const Header = styled.header<HeaderProps>`
  position: ${(props) => (props.static ? 'static' : 'fixed')};
  z-index: 2;
  top: 0;
  width: 100%;
  ${(props) =>
    props.noBorder
      ? ''
      : `border-bottom: 2px solid ${
          props.borderColor ? props.borderColor : 'rgba(44,44,44,0.2)'
        }`};
  background-color: ${(props) => props.bgColor};
  button {
    appearance: none;
    background-color: transparent;
    border: 0;
  }
  @media print {
    display: none;
  }
  @media (max-width: 1200px) {
    position: static;
  }

`

const HeaderInner = styled.div`
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 24px 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`

const Left = styled.div`
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.002em;
  @media (max-width: 1200px) {
    font-size: 16px;
    margin-bottom: 20px;
  }
`

const Right = styled.div`
  display: flex;
  gap: 10px;

  & > button {
    margin-left: 7px;
  }
`

const CloseWrap = styled.div`
  margin-right: -90px;
  margin-left: 30px;
  @media (max-width: 1200px) {
    margin: 0;
  }

`

const ProjectLink = styled.a`
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.06em;
  text-decoration: underline;
  color: #2c2c2c;
`
