import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { GameStateContext } from '../../contexts'
import { AppLayout, Button, H1, Row } from '../../facets'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import { MarkDown } from '../../facets/MarkDown'

export const SectorPresenter = () => {
  const { event, dispatch } = useContext(GameStateContext)

  useEffect(() => {
    window && window.scrollTo({ top: 0 })
  }, [])

  return (
    <AppLayout headerVariant="inGame" color="#fff" bgColor={event.sector.color}>
      <Section>
        <SectionInner>
          <Content>
            <H1>{event.sector.name[i18n.locale as 'de']}</H1>
            <ContentText>
              <MarkDown>{event.sector.desc[i18n.locale as 'de']}</MarkDown>
            </ContentText>
            <Row>
              <Button onClick={() => dispatch({ type: 'closeSection' })} arrow>
                <Trans id="event.next-event">Weiter</Trans>
              </Button>
            </Row>
          </Content>
          <Illustration
            style={{
              backgroundImage: `url("data:image/svg+xml;base64,${btoa(
                event.sector.illustration
              )}")`,
            }}
          />
        </SectionInner>
      </Section>
    </AppLayout>
  )
}

const Section = styled.div`
  flex: 1;
  padding: 110px 0 30px;
  box-sizing: border-box;
  display: flex;
  @media (max-width: 1200px) {
    padding: 20px 0 30px;
  }
`

const SectionInner = styled.div`
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 30px;
  box-sizing: border-box;
  display: flex;
  gap: 30px;
  @media (max-width: 1200px) {
    flex-direction: column-reverse;
  }
`

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const ContentText = styled.div`
  margin-top: 30px;
  margin-bottom: 40px;
  font-size: 22px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  font-weight: 300;
  @media (max-width: 1200px) {
    font-size: 16px;
  }
`

const Illustration = styled.div`
  flex: 0 0 400px;
  background-size: contain;
  background-position: bottom center;
  background-repeat: no-repeat;
  @media (max-width: 1200px) {
    //display: none;
  }
`
