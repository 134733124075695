import React from 'react'
import { ScoresChart } from './ScoresChart'

import { GameEvent, GameState } from '../model'
import { INTERESTS } from '../util.ts/interests'

interface EventScoresChartProps {
  event: GameEvent
  gameState: GameState
  onComplete?: () => void
}

export const EventScoresChart = (props: EventScoresChartProps) => {
  const { gameState, onComplete } = props
  const { scores } = gameState

  const isActive = () => {
    let active = {}
    INTERESTS.forEach((interest) => {
      const isHighlyAffected = true // isHighlyAffected(event.effect, interest.id)

      active = {
        ...active,
        ...{ [interest.id]: isHighlyAffected },
      }
    })
    return active
  }

  return (
    <ScoresChart value={scores} onComplete={onComplete} isActive={isActive()} />
  )
}
