import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { i18n } from '@lingui/core'
import { AppLayout, Button, Row } from '../../facets'
import { Trans } from '@lingui/macro'
import { GameStateContext } from '../../contexts'
import { DotScore } from '../../facets/DotScore'
import { INTERESTS } from '../../util.ts/interests'

export const RankingPresenter = () => {
  const { gameState, dispatch } = useContext(GameStateContext)
  const { rolesRanking } = gameState
  const locale = i18n.locale as 'de'

  useEffect(() => {
    window && window.scrollTo({ top: 0 })
  }, [])

  const playersAndScores = gameState.roles.map((role) => {
    const score =
      INTERESTS.map(
        (interest) => gameState.scores[interest.id] * role.interest[interest.id]
      ).reduce((a, b) => a + b, 0) + gameState.roleBonus[role.id]
    return {
      role,
      score,
    }
  })

  const roleScore = (roleId: string) => {
    const playerScore = playersAndScores.find((ps) => ps.role.id === roleId)
    return playerScore ? Math.round(playerScore.score * 25) : 0
  }

  return (
    <AppLayout headerVariant={'rankingOpen'}>
      <Page>
        <PageContent>
          {rolesRanking.map((ranking, i) => (
            <PlayerCard key={`ranking${i}`}>
              <Position>{ranking.rank}.</Position>
              <PlayerCardImage>
                <img
                  src={`data:image/svg+xml;base64,${btoa(ranking.role.avatar)}`}
                  alt={`Avatar ${ranking.role.name[locale]}`}
                />
              </PlayerCardImage>
              <PlayerCardContent>
                <PlayerName>{ranking.role.name[locale]}</PlayerName>
                <PlayerDesc>{ranking.role.jobDesc[locale]}</PlayerDesc>
              </PlayerCardContent>
              <PlayerCardMeta>
                <Points>
                  {roleScore(ranking.role.id)}{' '}
                  <Trans id="ranking.points-short">Pt</Trans>
                  <DotScore score={ranking.score} />
                </Points>
              </PlayerCardMeta>
            </PlayerCard>
          ))}
        </PageContent>
        <PageFooter>
          <PageAction>
            <Row>
              {gameState.gameState === 'playing' ? (
                <Button
                  onClick={() => {
                    dispatch({ type: 'closeRanking' })
                  }}
                  arrow
                >
                  <Trans id="ranking.back_to_game">Weiterspielen</Trans>
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    dispatch({ type: 'result' })
                  }}
                  arrow
                >
                  <Trans id="ranking.show-result">
                    Weiter zur Gesetzesvorlage
                  </Trans>
                </Button>
              )}
            </Row>
          </PageAction>
        </PageFooter>
      </Page>
    </AppLayout>
  )
}

const Page = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 110px 30px 170px;
  box-sizing: border-box;
  @media (max-width: 1200px) {
    padding: 20px 30px 170px;
  }
`

const PageContent = styled.div``

const PageFooter = styled.div`
  background-color: #e7e4db;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
`

const PageAction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 30px 30px;
  box-sizing: border-box;
  @media (max-width: 1200px) {
    align-items: center;
  }
`

const PlayerCard = styled.div`
  display: flex;
  align-items: center;
  padding: 25px 0px;

  background-color: #ffffff;
  border-bottom: 1px solid #e7e4db;

  &:last-child {
    border-bottom-width: 0px;
  }

  transition: 0.2s background-color, 0.2s border-color;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`

const PlayerCardImage = styled.div`
  padding: 0px 55px;
  img {
    border-radius: 50%;
    background-color: #fff;
    width: 120px;
    height: 120px;
  }
`

const PlayerCardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (max-width: 1200px) {
    width: 100%;
    max-width: 300px;
    justify-content: space-between;
  }
`

const PlayerCardMeta = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  img {
    width: 40px;
    margin: 0 -5px;
    border: 3px solid #fff;
    border-radius: 50%;
    position: relative;
    z-index: 40;
  }
  img:last-child,
  img:first-child {
    width: 26px;
    margin: 0px;
    border-width: 0;
    z-index: 10;
  }

  @media (max-width: 1200px) {
    width: 100%;
    max-width: 300px;
    justify-content: space-between;
  }
`

const Points = styled.div`
  display: flex;
  gap: 60px;

  @media (max-width: 1200px) {
    width: 100%;
    justify-content: space-between;
  }
`

const Position = styled.div`
  font-weight: 300;
  font-size: 28px;
`

const PlayerName = styled.div`
  font-weight: 300;
  font-size: 28px;
  line-height: 140%;
  margin-bottom: 5px;

  @media (max-width: 1200px) {
    text-align: center;
  }
`

const PlayerDesc = styled.div`
  font-weight: 300;
  font-size: 16px;
`
