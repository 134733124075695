import React, { useEffect } from 'react'
import { AppLayout, H3 } from '../../facets'
import { Trans } from '@lingui/macro'
import { PageIntro } from '../../facets/PageIntro'
import styled from 'styled-components'
import { INTERESTS } from '../../util.ts/interests'
import { i18n } from '@lingui/core'
import { ROLES } from '../../util.ts/roles'

export interface ManualPresenterProps {
  showBackToRoleSelection: boolean
}

export const ManualPresenter = () => {
  const locale = i18n.locale as 'de'

  useEffect(() => {
    window && window.scrollTo({ top: 0 })
  }, [])

  return (
    <AppLayout headerVariant="manualOpen">
      <PageIntro>
        <H3>
          <Trans id="manual.intro.title">Rollen</Trans>
        </H3>
      </PageIntro>
      <Cards>
        {ROLES.map((role) => (
          <Card key={role.id}>
            <CardImage
              src={`data:image/svg+xml;base64,${btoa(role.avatar)}`}
              alt={`Avatar ${role.name}`}
              width={160}
              height={160}
            />
            <CardContent>
              <div>{role.name[locale]}</div>
              <div>{role.jobDesc[locale]}</div>
            </CardContent>
          </Card>
        ))}
      </Cards>
      <PageIntro>
        <H3>
          <Trans id="manual.parameters.and.interests.title">Parameter</Trans>
        </H3>
      </PageIntro>
      <Cards>
        {INTERESTS.map((interest) => (
          <Card key={interest.id} width={190}>
            <CardImage
              src={`data:image/svg+xml;base64,${btoa(interest.icon)}`}
              alt={`Interest ${interest.name[locale]}`}
              width={120}
              height={120}
            />
            <CardContent>
              <div>{interest.name[locale]}</div>
            </CardContent>
          </Card>
        ))}
      </Cards>
    </AppLayout>
  )
}

const Cards = styled.div`
  padding: 0 40px;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

type CardProps = {
  width?: number
}

const Card = styled.div<CardProps>`
  width: ${(props) => (props.width ? props.width : 250)}px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
  padding: 18px 20px;
`

const CardImage = styled.img`
  border-radius: 50%;
  background-color: ##f8f7f3;
`

const CardContent = styled.div`
  flex: 1;
  padding: 20px 0px;
  text-align: center;
`
