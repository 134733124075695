import React, { useContext, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { GameStateContext, ScenariosContext } from '../../contexts'
import { i18n } from '@lingui/core'
import { AppLayout } from '../../facets'
export const ResultPresenter = () => {
  const { gameState } = useContext(GameStateContext)
  const { scenario } = useContext(ScenariosContext)
  const { events, sectors } = scenario

  useEffect(() => {
    window && window.scrollTo({ top: 0 })
  }, [])

  const sectorsResults = useMemo(() => {
    return sectors.map((sector) => {
      const results = events
        .map((event) => {
          if (event.sector.id !== sector.id) {
            return false
          }

          const pastDecisions = gameState.pastDecisions.find(
            (e) => e.eventId === event.id
          )

          if (!pastDecisions) {
            return false
          }

          let result = event.resultA[i18n.locale as 'de']
          if (pastDecisions.decision === 'B') {
            result = event.resultB[i18n.locale as 'de']
          }

          if (!result || result.length === 0) {
            return false
          }

          return result
        })
        .filter((e) => e)

      return {
        results,
        sector,
      }
    })
  }, [gameState, events, sectors])

  return (
    <AppLayout headerVariant={'resultOpen'}>
      <Page>
        {sectorsResults.map((sectorsResult) => (
          <React.Fragment key={sectorsResult.sector.id}>
            {sectorsResult.results.length > 0 && (
              <SectorWrap>
                <SectorIllustration>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sectorsResult.sector.illustrationSmall,
                    }}
                  />
                </SectorIllustration>
                <SectorContent>
                  <H1>{sectorsResult.sector.name[i18n.locale as 'de']}</H1>
                  {sectorsResult.results && (
                    <OL>
                      {sectorsResult.results.map((result, index) => (
                        <LI key={index}>
                          Artikel
                          <br />
                          <br />
                          {result}
                        </LI>
                      ))}
                    </OL>
                  )}
                </SectorContent>
              </SectorWrap>
            )}
          </React.Fragment>
        ))}
      </Page>
    </AppLayout>
  )
}
const Page = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 185px 30px 170px;
  box-sizing: border-box;
  @media print {
    padding: 30px;
  }
  @media (max-width: 1200px) {
    padding: 20px 30px 170px;
  }

`

const SectorWrap = styled.div`
  display: flex;
  gap: 200px;
  @media print {
    gap: 50px;
  }
  margin-bottom: 70px;
  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 20px;
  }
`

const SectorIllustration = styled.div`
  svg {
    width: 294px;
    height: 294px;
    @media print {
      width: 150px;
      height: 150px;
    }
  }
  @media (max-width: 1200px) {
    display: flex;
    justify-content: center;
  }
`

const SectorContent = styled.div``

const H1 = styled.h1`
  font-weight: 300;
  font-size: 28px;
  letter-spacing: 0.02em;
  margin-bottom: 30px;
`

const OL = styled.ol`
  margin: 0 0 0 1.4em;
  padding: 0;
`

const LI = styled.li`
  font-weight: 300;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.02em;
  margin: 0 0 30px 0;
  padding-left: 1em;
`
