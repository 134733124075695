import React from 'react'
import styled, { css } from 'styled-components'

type ResponsiveValue =
  | {
      mobile: string
      desktop: string
    }
  | string
type FlexDirection = 'row' | 'column'

interface RowProps {
  children: React.ReactNode
  justifyContent?: string
  marginTop?: ResponsiveValue
  marginBottom?: ResponsiveValue
  flexDirection?: FlexDirection
  flexWrap?: boolean
}

interface ParentProps {
  justifyContent?: string
  marginTop?: ResponsiveValue
  marginBottom?: ResponsiveValue
  flexDirection?: FlexDirection
  flexWrap?: boolean
}

interface ChildProps {
  flex?: number
}

const ResponsiveValueCss = ({
  type,
  value,
}: {
  type: string
  value?: string | ResponsiveValue
}) => {
  if (!value) return ''
  if (typeof value === 'string') {
    return `
      ${type}: ${value};
    `
  }
  return `
    ${type}: ${value.mobile};
    @media only screen and (min-width: 600px) {
      ${type}: ${value.desktop};
    }
  `
}

const Parent = styled.div<ParentProps>`
  display: flex;

  ${(props) =>
    css`
      flex-direction: ${props.flexDirection || 'row'};
    `}
  ${(props) =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `}
  ${(props) =>
    ResponsiveValueCss({ type: 'margin-top', value: props.marginTop })}
  ${(props) =>
    ResponsiveValueCss({ type: 'margin-bottom', value: props.marginBottom })}

    ${(props) => (props.flexWrap ? 'flex-wrap: wrap;' : '')}
`

const Child = styled.div<ChildProps>`
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}
`

export const Row = ({
  children,
  justifyContent,
  marginTop,
  marginBottom,
  flexDirection,
  flexWrap,
}: RowProps) => (
  <Parent
    marginTop={marginTop}
    marginBottom={marginBottom}
    justifyContent={justifyContent}
    flexDirection={flexDirection}
    flexWrap={flexWrap}
  >
    {children}
  </Parent>
)
Row.Item = Child
