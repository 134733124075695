import React, { useContext, useEffect } from 'react'
import { i18n } from '@lingui/core'

import { GameStateContext } from '../../contexts'
import { Button, Row, AppLayout, StaticEnH1 } from '../../facets'

import { Trans } from '@lingui/macro'
import styled from 'styled-components'

export const IntroPresenter = () => {
  const { dispatch } = useContext(GameStateContext)

  useEffect(() => {
    window && window.scrollTo({ top: 0 })
  }, [])

  return (
    <AppLayout headerVariant="intro">
      <Wrap>
        <Row justifyContent="center">
          <Teaser src="/images/illustration/intro.svg" />
        </Row>
        <Row justifyContent="center" marginTop="1.5rem">
          <StaticEnH1 center>
            <Trans id="language.heading">Klimaspiel</Trans>
          </StaticEnH1>
        </Row>
        <Row justifyContent="center" marginTop="1.5rem" marginBottom="2.5rem">
          <Button onClick={() => dispatch({ type: 'start' })} arrow>
            <Trans id="language.start">Spiel starten</Trans>
          </Button>
        </Row>
        <LogosWrap>
          <LogoGroup>
            <LogoGroupTitle>
              <Trans id="language.developed_by">
                Dieses Spiel wurde entwickelt von:
              </Trans>
            </LogoGroupTitle>
            <LogoGroupContent>
              <a
                href={i18n._({ id: 'language.link_uni_be' })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/images/logo-uni-be.svg" alt="Uni BE" />
              </a>
              <a
                href={i18n._({ id: 'language.link_zeilenwerk' })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/images/logo-zeilenwerk.svg" alt="Zeilenwerk" />
              </a>
            </LogoGroupContent>
          </LogoGroup>
          <LogoGroup>
            <LogoGroupTitle>
              <Trans id="language.financed_by">
                Mit finanzieller Unterstützung durch:
              </Trans>
            </LogoGroupTitle>
            <LogoGroupContent>
              <a
                href={i18n._({ id: 'language.link_fnnf' })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/images/logo-fnnf.svg" alt="SNF" />
              </a>
            </LogoGroupContent>
          </LogoGroup>
        </LogosWrap>
      </Wrap>
    </AppLayout>
  )
}

const Wrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Teaser = styled.img`
  width: 100%;
`

const LogosWrap = styled.div`
  max-width: 944px;
  width: 100%;
  display: flex;
  gap: 20px;
  margin-top: 40px;
  margin-bottom: 60px;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`

const LogoGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const LogoGroupTitle = styled.div`
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.06em;

  color: #1a2038;
  @media (max-width: 1200px) {
    text-align: center;
  }
`

const LogoGroupContent = styled.div`
  display: flex;
  gap: 20px;
  img {
    width: 100%;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
  }

`
