import styled, { createGlobalStyle } from 'styled-components'

type Color = string | undefined
type TextAlignCenter = boolean | undefined

export interface DefaultHeadingOptionsProps {
  color?: Color
  center?: TextAlignCenter
}

interface H5Props extends DefaultHeadingOptionsProps {
  variant?: 'uppercase'
}

interface H6Props extends DefaultHeadingOptionsProps {
  variant?: 'light'
}

const fontColor = (color: Color) => {
  if (color) return `color: ${color};`
  return ''
}

const textAlign = (center: TextAlignCenter) => {
  if (center) return `text-align: center;`
  return ''
}

const defaultHeadingOptions = ({
  color,
  center,
}: DefaultHeadingOptionsProps) => {
  return `
    ${fontColor(color)}
    ${textAlign(center)}
`
}

const breakPointDesktopFont = '600px'

export const RootStyle = createGlobalStyle<DefaultHeadingOptionsProps>`
  body, button {
    font-family: 'Unbounded', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
    ${(props) => defaultHeadingOptions(props)}}
  }
`

export const StaticEnH1 = styled.h1<DefaultHeadingOptionsProps>`
  font-size: 40px;
  line-height: 1.3;
  font-weight: 700;
  font-family: 'Unbounded', sans-serif !important;
  @media only screen and (min-width: 905px) {
    font-size: 120px;
  }
  ${(props) =>
    defaultHeadingOptions({ color: props.color, center: props.center })}}

  `

export const Span = styled.span<DefaultHeadingOptionsProps>`
  ${(props) => defaultHeadingOptions(props)}}
`

export const B = styled.b<DefaultHeadingOptionsProps>`
  font-size: 16px;
  line-height: 1.4;
  font-weight: 600;
  @media only screen and (min-width: ${breakPointDesktopFont}) {
    font-size: 18px;
  }
  ${(props) => defaultHeadingOptions(props)}}
`

export const H1 = styled.h1<DefaultHeadingOptionsProps>`
  font-size: 34px;
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.02em;
  @media only screen and (min-width: ${breakPointDesktopFont}) {
    font-size: 50px;
  }
  @media (max-width: 1200px) {
    font-size: 20px;
  }
  ${(props) => defaultHeadingOptions(props)}}
`

export const H2 = styled.h2<DefaultHeadingOptionsProps>`
  font-size: 24px;
  line-height: 1.3;
  font-weight: 600;
  @media only screen and (min-width: ${breakPointDesktopFont}) {
    font-size: 32px;
  }
  ${(props) => defaultHeadingOptions(props)}}
`

export const H3 = styled.h3<DefaultHeadingOptionsProps>`

  font-weight: 700;
  font-size: 24px;
  line-height: 160%;

  text-align: center;
  letter-spacing: 0.02em;

  ${(props) => defaultHeadingOptions(props)}}
`

export const H4 = styled.h4<DefaultHeadingOptionsProps>`
  font-size: 20px;
  line-height: 1.2;
  font-weight: 600;
  @media only screen and (min-width: ${breakPointDesktopFont}) {
    font-size: 26px;
    line-height: 1.4;
  }
  ${(props) => defaultHeadingOptions(props)}}
`

export const H5 = styled.h5<H5Props>`
  font-size: 14px;
  line-height: 1.2;
  font-weight: 600;
  letter-spacing: 0.03em;
  ${(props) =>
    props.variant === 'uppercase' &&
    `
      letter-spacing: 0.12em;
      text-transform: uppercase;
      @media only screen and (min-width: ${breakPointDesktopFont}) {
        font-size: 16px;
      }
  `}
`

export const H6 = styled.h6<H6Props>`
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 0.03em;
  @media only screen and (min-width: ${breakPointDesktopFont}) {
    font-size: 14px;
  }
  ${(props) =>
    props.variant === 'light' &&
    `
      font-weight: 300;
      letter-spacing: 0.05em;
      @media only screen and (min-width: ${breakPointDesktopFont}) {
        font-size: 14px;
      }
  `}
`

export const ButtonFontStyle = () => `
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.02em;
`
