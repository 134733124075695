import React, { useContext, useEffect, useRef } from 'react'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import { GameStateContext, ScenariosContext } from '../../contexts'
import { AppLayout, Button, Warning } from '../../facets'
import { EventScoresChart, EventDecision } from '../../controls'
import { DecisionOption } from '../../model'
import styled from 'styled-components'
import { MarkDown } from '../../facets/MarkDown'

export const EventPresenter = () => {
  const { gameState, event, dispatch } = useContext(GameStateContext)

  const { scenario } = useContext(ScenariosContext)
  const isMounted = useRef(true)

  useEffect(() => {
    window && window.scrollTo({ top: 0 })
    return () => {
      isMounted.current = false
    }
  }, [])

  if (!(scenario && event))
    return (
      <Trans id="scenario.error.occurred">Es ist ein Fehler aufgetreten!</Trans>
    )

  const decision = gameState.pastDecisions.find(
    (d) => d.eventId === event.id
  )?.decision

  if (!event.info[i18n.locale as 'de'] && gameState.currentEventDisplayInfo) {
    dispatch({ type: 'showEventQuestion' })
  }

  if (event.noDecision && !decision) {
    dispatch({ type: 'resolveEvent', event, decision: DecisionOption.A })
  }

  const renderContent = () => {
    let textQuestion = ''
    const locale = i18n.locale as 'de'

    if (gameState.currentEventDisplayInfo) {
      textQuestion = event.info[locale]
    } else {
      textQuestion = event.question[locale]
    }

    // Headline - Auto Resolved Event, always show question text
    if (event.noDecision) {
      textQuestion = event.question[locale]
    }

    return (
      <>
        <FadeOut
          hide={decision !== undefined && !event.noDecision}
          animate={decision !== undefined && !event.noDecision}
        >
          <MarkDown>{textQuestion}</MarkDown>
        </FadeOut>

        <FadeIn show={decision !== undefined && !event.noDecision}>
          <MarkDown>
            {decision !== undefined && !event.noDecision
              ? event.explanation[decision][locale]
              : ''}
          </MarkDown>
        </FadeIn>

        {!event.noDecision && !decision && (
          <Skip
            href="#"
            onClick={(e) => {
              e.preventDefault()
              dispatch({ type: 'skipEvent' })
            }}
          >
            <Trans id="event.skip">Frage überspringen</Trans>
          </Skip>
        )}
      </>
    )
  }

  const renderContentImage = () =>
    event.noDecision ? (
      <img src="/images/illustration/headline.svg" alt="headline" />
    ) : null

  const renderScore = () => (
    <EventScoresChartWrap>
      <EventScoresChart event={event} gameState={gameState}></EventScoresChart>
    </EventScoresChartWrap>
  )

  const renderActionsButtons = () => (
    <>
      <EventDecision />
      {event.noDecision && !decision && (
        <Button
          size="small"
          onClick={() => {
            window && window.scrollTo({ top: 0 })
            dispatch({
              type: 'resolveEvent',
              event,
              decision: 'A' as DecisionOption,
            })
          }}
          arrow
        >
          <Trans id="event.next-event">Weiter</Trans>
        </Button>
      )}
      {decision && (
        <Button
          size="small"
          onClick={() => {
            window && window.scrollTo({ top: 0 })
            dispatch({
              type: 'nextEvent',
            })
          }}
          arrow
        >
          <Trans id="event.next-event">Weiter</Trans>
        </Button>
      )}
    </>
  )

  const renderActionsInfo = () => (
    <>
      {!decision && event.specialRulesText[i18n.locale as 'de'] && (
        <Warning roles={event.specialRulesRoles}>
          <MarkDown>{event.specialRulesText[i18n.locale as 'de']}</MarkDown>
        </Warning>
      )}
    </>
  )

  return (
    <AppLayout
      headerVariant="inGame"
      color="#fff"
      bgColor={event.noDecision ? '#9A988F' : event.sector.color}
    >
      <Event>
        <EventInfo>
          <Section>
            <Left>
              <ContentText>{renderContent()}</ContentText>
            </Left>
            <Right>{renderContentImage()}</Right>
          </Section>
        </EventInfo>
        <EventMeta>
          <Section>
            <Left>{renderScore()}</Left>
            <Right>
              <EventMetaActionsInfo>{renderActionsInfo()}</EventMetaActionsInfo>
              <EventMetaActionsButtons>
                {renderActionsButtons()}
              </EventMetaActionsButtons>
            </Right>
          </Section>
        </EventMeta>
      </Event>
    </AppLayout>
  )
}

const EventScoresChartWrap = styled.div`
  display: flex;
  flex-direction: column;
  background: repeating-linear-gradient(
        to bottom,
        transparent 0 24px,
        #ebe9e4 24px 36px
      )
      25%/3px 100% no-repeat,
    repeating-linear-gradient(to bottom, transparent 0 24px, #ebe9e4 24px 36px)
      50%/3px 100% no-repeat,
    repeating-linear-gradient(to bottom, transparent 0 24px, #ebe9e4 24px 36px)
      75%/3px 100% no-repeat;
`

const Event = styled.div``

const EventInfo = styled.div`
  flex: 1;
  padding: 110px 0 550px;
  font-size: 22px;
  @media (max-width: 1200px) {
    padding: 20px 0 0;
  }
`

const EventMeta = styled.div`
  position: fixed;
  bottom: 0;
  height: 450px;
  width: 100%;
  background-color: #f8f7f3;
  color: #2c2c2c;
  padding: 30px 0;
  @media (max-width: 1200px) {
    position: initial;
    height: initial;
    margin-top: 20px;
  }
`

const EventMetaActionsInfo = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  padding: 29px 0 5px;
`

const EventMetaActionsButtons = styled.div`
  flex: 0;
  padding: 5px 0 29px;
`

const Section = styled.div`
  display: flex;
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 30px;
  box-sizing: border-box;
  gap: 30px;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`

const Left = styled.div`
  flex: 1;
`

const Right = styled.div`
  flex: 0 0 400px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    flex: initial;
  }
`

const ContentText = styled.div`
  font-size: 22px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  @media (max-width: 1200px) {
    font-size: 16px;
  }
`

const Skip = styled.a`
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.06em;
  color: #ffffff;
  text-decoration: underline;
  display: inline-block;
  margin-top: 1.5em;
`

type FadeOutProps = {
  hide: boolean
  animate: boolean
}

const FadeOut = styled.div<FadeOutProps>`
  max-height: 2000px;
  overflow: hidden;
  transition: max-height ${(props) => (props.animate ? '2s 1.5s' : '0s')}
    cubic-bezier(0, 1, 0, 1);
  ${(props) => (props.hide ? 'max-height: 0px;' : '')}
  @media (max-width: 1200px) {
    transition: none;
  }
`

type FadeInProps = {
  show: boolean
}

const FadeIn = styled.div<FadeInProps>`
  max-height: 0px;
  overflow: hidden;
  transition: max-height 2s 3s linear;
  ${(props) => (props.show ? 'max-height: 2000px;' : '')}
  @media (max-width: 1200px) {
    transition: none;
  }
`
