import React from 'react'
import styled from 'styled-components'

import { textLight } from '../theme'

const Wrapper = styled.div`
  display: flex;
  color: ${textLight};
  text-align: center;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  justify-content: flex-end;
  letter-spacing: 0.1em;
  color: #cdcab9;
`
const Figure = styled.p``

interface IndicatorProps {
  figure: number
  maximum?: number
}
export const Indicator = ({ figure, maximum }: IndicatorProps) => (
  <Wrapper>
    &nbsp;<Figure>{maximum ? `${figure}/${maximum}` : figure}</Figure>
  </Wrapper>
)
