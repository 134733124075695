/*
import React, { Dispatch } from 'react'

import { GameEvent, GameState } from '../model'
import { GameStateActionI } from '../reducers/gameStateReducer'

interface GameStateContextI {
  gameState: GameState
  event: GameEvent
  eventCount: number
  dispatch: Dispatch<GameStateActionI>
}
export const GameStateContext = React.createContext<GameStateContextI>(
  {} as GameStateContextI
)
*/

import React, { useEffect, useContext } from 'react'
import { ReactNode } from 'react-markdown'
import { ScenariosContext } from './ScenariosContext'
import { useGameState } from '../hooks'
import { GameEvent, GameState } from '../model'
import { INITIAL_GAME_STATE } from '../util.ts/gameState'

const GameStateContext = React.createContext<{
  gameState: GameState
  event: GameEvent
  eventCount: number
  dispatch: any
}>({
  gameState: INITIAL_GAME_STATE,
  event: {
    id: -1,
    question: { de: '' },
    optionA: { de: '' },
    optionB: { de: '' },
    info: { de: '' },
    explanation: { A: { de: '' }, B: { de: '' } },
    effect: { A: {}, B: {} },
    roleEffect: { A: {}, B: {} },
    noDecision: false,
    initiallyLocked: false,
    unlocks: { A: [], B: [] },
    groups: [],
    followingQuestionGroupsOptionA: [],
    followingQuestionGroupsOptionB: [],
    sector: {
      id: '-1',
      name: { de: '' },
      desc: { de: '' },
      color: '#000',
      illustration: '',
      illustrationSmall: '',
    },
    specialRulesText: { de: '' },
    specialRulesRoles: [],
    resultA: { de: '' },
    resultB: { de: '' },
  },
  eventCount: 0,
  dispatch: () => {},
})

function GameStateContextProvider({ children }: { children: ReactNode }) {
  const { scenario } = useContext(ScenariosContext)
  const [gameState, dispatch] = useGameState()

  useEffect(() => {
    dispatch({ type: 'loadGameState' })
  }, [dispatch])

  useEffect(() => {
    dispatch({ type: 'saveGameState' })
  }, [gameState, dispatch])

  if (!gameState || !scenario) {
    return null
  }

  const event = scenario.events.find(
    (event) => event.id === gameState.currentEventId
  )

  if (!event) {
    return null
  }

  const eventCount = scenario.events.length

  return (
    <GameStateContext.Provider
      value={{ gameState, event, eventCount, dispatch }}
    >
      {children}
    </GameStateContext.Provider>
  )
}

export { GameStateContextProvider, GameStateContext }
