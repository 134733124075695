import React from 'react'
import styled from 'styled-components'
import { Role } from '../model'

interface WarningProps {
  children: React.ReactNode
  roles?: Role[]
}
export const Warning = ({ children, roles }: WarningProps) => {
  return (
    <Wrapper>
      <Content>{children}</Content>
      {roles && (
        <RoleWrap>
          {roles.map((role) => (
            <div
              key={role.id}
              dangerouslySetInnerHTML={{ __html: role.avatarSmall }}
            />
          ))}
        </RoleWrap>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex: 1;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: #f0c2c2;
  border-radius: 5px;
  padding: 20px 20px;
`

const Content = styled.div`
  flex: 1;
`

const RoleWrap = styled.div`
  flex: 0;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  svg {
    width: 40px;
    height: 40px;
  }
`
