import { motion } from 'framer-motion'
import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

export type StrokeColor = 'dark' | 'light' | 'white'

const strokeColorFn = (props: SegmentProps) =>
  props.strokeColor === 'dark'
    ? '#CE9696'
    : props.strokeColor === 'light'
    ? '#5FB7A2'
    : '#2C2C2C'

interface ProgressIndicatorProps {
  icon: string
  value: number
  active?: boolean
  borderColor?: string
  onComplete?: () => void
}

interface BarProps {
  borderColor?: string
}

interface SegmentProps {
  value: number
  strokeColor?: string
  active?: boolean
}

interface BarImageProps {
  active?: boolean
}

const Bar = styled.div<BarProps>`
  flex-grow: 1;
  padding: 5px;
  align-items: center;
  border-radius: 11px;
  ${(props) => css`
    background: ${props.borderColor
      ? props.borderColor
      : 'rgba(255, 255, 255, 1)'};
  `}
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  justify-content: flex-start;

  @media only screen and (max-width: 600px) {
    padding: 4px;
    border-radius: 8px;
  }
`
const Segment = styled(motion.div)<SegmentProps>`
  height: 12px;
  border-radius: 6px;
  ${(props) => css`
    width: ${Math.round(props.value * 100)}%;
  `}
  min-width: 42px;
  ${(props) =>
    props.active &&
    `
    min-width: 50px;
  `}
  background: ${strokeColorFn};
  transition: width 1s ease;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transform-origin: center center;
  position: relative;
  @media only screen and (max-width: 600px) {
    height: 8px;
    border-radius: 4px;
    min-width: 34px;
    ${(props) =>
      props.active &&
      `
      min-width: 41px;
    `}
  }
`

const BarImage = styled.div<BarImageProps>`
  width: 46px;
  height: 46px;
  right: 0;
  ${(props) =>
    props.active &&
    `
    width: 64px;
    height: 64px;
    right: -8px; 
  `}
  margin-left: calc(50% - 50vw);
  border-radius: 50%;
  position: absolute;
  img {
    width: 100%;
  }
  @media only screen and (max-width: 600px) {
    width: 36px;
    height: 36px;
    ${(props) =>
      props.active &&
      `
      width: 52px;
      height: 52px;
    `}
  }
`

export const ProgressIndicator = ({
  value,
  icon,
  active,
  borderColor,
}: ProgressIndicatorProps) => {
  // force value between 0 and 1
  value = Math.min(1, Math.max(0, value))

  const prevValue = useRef<undefined | number>()

  useEffect(() => {
    prevValue.current = value
  }, [value])

  return (
    <Bar borderColor={borderColor}>
      <Segment
        animate={{
          width: `${Math.round(value * 100)}%`,
        }}
        transition={{
          duration: 0.5,
        }}
        value={value}
        strokeColor={
          prevValue.current &&
          Math.round(value * 100) !== Math.round(prevValue.current * 100)
            ? value > prevValue.current
              ? 'light'
              : 'dark'
            : 'white'
        }
        active={active}
      >
        <BarImage active={active}>
          <img
            src={`data:image/svg+xml;base64,${btoa(icon)}`}
            alt={`Interest`}
          />
        </BarImage>
      </Segment>
    </Bar>
  )
}
