import React from 'react'
import { Router } from '@reach/router'

import { Scenario } from './pages'
import { i18n } from '@lingui/core'
import { messages } from './locales/de/messages'
import { I18nProvider } from '@lingui/react'
import { HelmetProvider } from 'react-helmet-async'
import { GameStateContextProvider, ScenariosContextProvider } from './contexts'

i18n.load('de', messages)
i18n.activate('de')

export default function App() {
  return (
    <I18nProvider i18n={i18n}>
      <HelmetProvider>
        <ScenariosContextProvider>
          <GameStateContextProvider>
            <Router>
              <Scenario path="/" />
            </Router>
          </GameStateContextProvider>
        </ScenariosContextProvider>
      </HelmetProvider>
    </I18nProvider>
  )
}
