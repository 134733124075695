import { Interest } from '../model/Interest'
import { getTranslated } from './helpers'

const interestsData = require('../static/interests.json')

export const INTERESTS: Interest[] = interestsData
  .map((i: { [key: string]: string }): Interest => {
    return {
      id: i.id as string,
      name: getTranslated(i, 'name'),
      sort: parseInt(i.sort),
      icon: i.icon,
    }
  })
  .sort(function (a: Interest, b: Interest) {
    return a.sort - b.sort
  })
