import React, { useEffect } from 'react'
import styled from 'styled-components'
import { GameHeaderPresenter, GameHeaderVariants } from '../presenters'
import { i18n } from '@lingui/core'
import { RootStyle } from './Typography'
import { Helmet } from 'react-helmet-async'
import { Color } from '../model/Color'

interface AppLayoutProps extends GameHeaderVariants {
  children: React.ReactNode
  color?: Color
  bgColor?: Color
}

export const AppLayout = ({
  children,
  color = '#2c2c2c',
  bgColor = '#fff',
  headerVariant,
}: AppLayoutProps) => {
  useEffect(() => {
    document.title = i18n._({ id: 'head.title' })
  }, [])

  return (
    <App color={color} bgColor={bgColor}>
      <Helmet>
        <title>{i18n._({ id: 'head.title' })}</title>
        <meta name="description" content={i18n._({ id: 'head.meta.desc' })} />
      </Helmet>
      {headerVariant && (
        <GameHeaderPresenter headerVariant={headerVariant} bgColor={bgColor} />
      )}
      {children}
      <RootStyle />
    </App>
  )
}

interface AppStyleProps {
  color: Color
  bgColor: Color
}

const App = styled.div<AppStyleProps>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  color: ${(props) => props.color};
  background-color: ${(props) => props.bgColor};
`
