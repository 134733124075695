// The maximum number of events until the game ends
export const MAX_EVENTS_COUNT = 10

// The initial value for the global score
export const INITIAL_GLOBAL_SCORE = 0.5

// Score values below this will hurt the global score
export const GLOBAL_SCORE_MALUS_THRESHOLD = 0.1

// The group to use in order to pick the first question
export const INITIAL_EVENT_GROUP = 'random'
