import React from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'

interface MarkDownProps {
  children: string
}
export const MarkDown = ({ children }: MarkDownProps) => (
  <Wrapper>
    <ReactMarkdown>{children}</ReactMarkdown>
  </Wrapper>
)

const Wrapper = styled.div`
  p:not(:last-child) {
    margin-bottom: 1em;
  }
`
