import React from 'react'

export const IconCloseRanking = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="36"
      height="36"
      rx="18"
      stroke="#747885"
      strokeOpacity="0.1"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2897 25.2469C12.9034 24.8331 12.9034 24.1964 13.2897 23.7825L13.2967 23.775L17.7823 19.2896L13.289 14.7963C12.9234 14.4025 12.9235 13.7665 13.2892 13.3727C13.6792 12.9549 14.3471 12.9243 14.7687 13.3177L14.7762 13.3248L19.2616 17.8104L23.7942 13.2778C24.2157 12.8843 24.8833 12.9145 25.2733 13.3323C25.6654 13.7525 25.6369 14.417 25.2229 14.8075L20.7408 19.2896L25.2028 23.7516C25.2456 23.7819 25.2747 23.8143 25.2919 23.835C25.3007 23.8455 25.3097 23.8572 25.3138 23.8626L25.3169 23.8657L25.3266 23.8777C25.676 24.3076 25.6669 24.978 25.2033 25.3547C24.7705 25.7063 24.1331 25.6879 23.7405 25.2478L19.2616 20.7689L14.7761 25.2543C14.3604 25.6701 13.7126 25.6701 13.2969 25.2543L13.2897 25.2469Z"
      fill="#747885"
      fillOpacity="0.5"
    />
  </svg>
)

export const IconInfoClose = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2897 25.2469C12.9034 24.8331 12.9034 24.1964 13.2897 23.7825L13.2967 23.775L17.7823 19.2896L13.289 14.7963C12.9234 14.4025 12.9235 13.7665 13.2892 13.3727C13.6792 12.9549 14.3471 12.9243 14.7687 13.3177L14.7762 13.3248L19.2616 17.8104L23.7942 13.2778C24.2157 12.8843 24.8833 12.9145 25.2733 13.3323C25.6654 13.7525 25.6369 14.417 25.2229 14.8075L20.7408 19.2896L25.2028 23.7516C25.2456 23.7819 25.2747 23.8143 25.2919 23.835C25.3007 23.8455 25.3097 23.8572 25.3138 23.8626L25.3169 23.8657L25.3266 23.8777C25.676 24.3076 25.6669 24.978 25.2033 25.3547C24.7705 25.7063 24.1331 25.6879 23.7405 25.2478L19.2616 20.7689L14.7761 25.2543C14.3604 25.6701 13.7126 25.6701 13.2969 25.2543L13.2897 25.2469Z"
      fill="#747885"
      fillOpacity="0.5"
    />
  </svg>
)

export const CoffeeBean = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.31 12.2969L10.7881 14.926C10.3371 17.1984 8.26621 18.7744 5.98764 18.7162C5.86174 18.713 5.30383 17.4027 5.30383 17.4027C4.92889 17.3305 3.9448 18.3439 3.61164 18.1934C1.65749 17.3118 0.484668 15.1886 0.914079 13.025L1.4359 10.3958C1.89589 8.07817 4.04148 6.48549 6.3734 6.61154C6.3734 6.61154 8.64319 7.14072 8.76724 7.20216C10.6282 8.12355 11.7278 10.1915 11.31 12.2969Z"
      fill="#A06564"
    />
    <path
      d="M11.3084 12.297L10.7866 14.9262C10.3356 17.1982 8.26458 18.7745 5.98606 18.7163C5.98462 18.7161 5.9831 18.7163 5.98167 18.716C5.84865 18.685 5.3023 17.4028 5.3023 17.4028C5.26366 17.3954 5.2183 17.3993 5.16765 17.4125L7.26929 6.82349C7.91856 6.97824 8.69477 7.16719 8.76604 7.20238C8.77187 7.20545 8.77779 7.20805 8.78362 7.21113C10.6335 8.13652 11.725 10.1978 11.3084 12.297V12.297Z"
      fill="#875959"
    />
    <path
      d="M7.62295 15.9076L5.98636 18.7162C5.78119 18.7144 5.33434 18.6576 5.19744 18.6312L4.67461 18.5306C4.29966 18.4584 3.9435 18.344 3.61034 18.1935L4.7755 15.497C5.12284 14.6927 5.11386 13.7822 4.75001 12.9854L4.54777 12.5421C4.14301 11.6544 4.17957 10.6317 4.64688 9.77445L6.37211 6.61164C6.58826 6.62301 6.80638 6.6494 7.02438 6.69137L7.54721 6.79203C7.8382 6.84805 8.40187 6.98272 8.78369 7.21115L7.44995 9.88194C7.41092 10.0012 7.37961 10.1216 7.35546 10.2432C7.20468 11.003 7.34383 11.7965 7.75517 12.4664C8.41244 13.5368 8.36037 14.8897 7.62295 15.9076V15.9076Z"
      fill="#684B52"
    />
    <path
      d="M7.62256 15.9078L5.98604 18.716C5.98451 18.7161 5.98308 18.7159 5.98156 18.7161C5.7745 18.7138 5.33299 18.6575 5.19704 18.6314L4.93563 18.581L7.2854 6.74182L7.54681 6.79215C7.8378 6.84817 8.40148 6.98255 8.7835 7.21121L7.4496 9.88207C7.41057 10.0014 7.37926 10.1217 7.35511 10.2434C7.20433 11.0031 7.34348 11.7967 7.75482 12.4666C8.41205 13.537 8.35997 14.8898 7.62256 15.9078Z"
      fill="#563F46"
    />
    <path
      d="M20.0861 7.97385L19.5643 10.603C19.1132 12.8754 17.0424 14.4514 14.7638 14.3932C14.6379 14.39 14.08 13.0797 14.08 13.0797C13.705 13.0075 12.7209 14.0209 12.3878 13.8704C10.4336 12.9888 9.26079 10.8656 9.6902 8.702L10.212 6.07284C10.672 3.75517 12.8176 2.16249 15.1495 2.28854C15.1495 2.28854 17.4193 2.81771 17.5434 2.87917C19.4043 3.80055 20.5039 5.86846 20.0861 7.97385V7.97385Z"
      fill="#A06564"
    />
    <path
      d="M20.0845 7.97399L19.5627 10.6032C19.1117 12.8752 17.0407 14.4515 14.7622 14.3933C14.7607 14.3931 14.7592 14.3933 14.7578 14.393C14.6248 14.362 14.0784 13.0798 14.0784 13.0798C14.0398 13.0724 13.9944 13.0763 13.9438 13.0895L16.0454 2.50049C16.6947 2.65525 17.4709 2.8442 17.5422 2.87938C17.548 2.88245 17.5539 2.88506 17.5597 2.88813C19.4096 3.81352 20.5011 5.8748 20.0845 7.97399V7.97399Z"
      fill="#875959"
    />
    <path
      d="M16.3991 11.5846L14.7625 14.3932C14.5573 14.3914 14.1105 14.3346 13.9736 14.3082L13.4507 14.2076C13.0758 14.1354 12.7196 14.021 12.3865 13.8705L13.5516 11.174C13.899 10.3697 13.89 9.45922 13.5261 8.66237L13.3239 8.21906C12.9191 7.33142 12.9557 6.30875 13.423 5.45145L15.1482 2.28864C15.3644 2.30002 15.5825 2.3264 15.8005 2.36837L16.3233 2.46903C16.6143 2.52505 17.178 2.65972 17.5599 2.88816L16.2261 5.55895C16.1871 5.67826 16.1558 5.79856 16.1316 5.92025C15.9808 6.67996 16.12 7.47355 16.5313 8.14344C17.1886 9.21384 17.1365 10.5667 16.3991 11.5846V11.5846Z"
      fill="#684B52"
    />
    <path
      d="M16.3984 11.5846L14.7619 14.3928C14.7604 14.393 14.759 14.3928 14.7574 14.3929C14.5504 14.3906 14.1089 14.3344 13.9729 14.3082L13.7115 14.2579L16.0613 2.4187L16.3227 2.46903C16.6137 2.52505 17.1774 2.65943 17.5594 2.88809L16.2255 5.55895C16.1864 5.67826 16.1551 5.79856 16.131 5.92025C15.9802 6.67996 16.1194 7.47355 16.5307 8.14344C17.1879 9.21383 17.1358 10.5667 16.3984 11.5846V11.5846Z"
      fill="#563F46"
    />
  </svg>
)

export const CoffeeBeanSvgMaskRight = () => (
  <svg width="25px" height="25px" viewBox="0 0 25 25">
    <rect width="25" height="25" fill="black" />
    <path
      d="M13.5 4.5L11 0H25V25H13.5L15 22.5L11 21L14.5 19L16.5 16V15L13.5 14L11 11.5L12.5 9L10.5 8.5L11 7L13.5 4.5Z"
      fill="white"
    />
  </svg>
)
export const CoffeeBeanSvgMaskLeft = () => (
  <svg width="25px" height="25px" viewBox="0 0 25 25">
    <rect width="25" height="25" fill="white" />
    <path
      d="M13.5 4.5L11 0H25V25H13.5L15 22.5L11 21L14.5 19L16.5 16V15L13.5 14L11 11.5L12.5 9L10.5 8.5L11 7L13.5 4.5Z"
      fill="black"
    />
  </svg>
)

export const CoffeeBeanSvg = ({
  x,
  y,
  mask,
}: {
  x: number
  y: number
  mask: string
}) => (
  <svg
    x={x - 12.5}
    y={y - 12.5}
    width="25px"
    height="25px"
    viewBox="0 0 25 25"
    mask={mask}
  >
    <path
      d="M25.0003 12.499C25.0003 11.5088 24.8846 10.5455 24.6669 9.62165L19.8009 4.51123L5.29785 20.5685L9.15142 24.5435C10.2174 24.8393 11.3405 24.998 12.5008 24.998C19.4041 24.998 25.0003 19.402 25.0003 12.499Z"
      fill="#FFD261"
    />
    <path
      d="M12.4995 24.9979C19.4028 24.9979 24.9991 19.402 24.9991 12.499C24.9991 5.59598 19.4028 0 12.4995 0C5.59623 0 0 5.59598 0 12.499C0 19.402 5.59623 24.9979 12.4995 24.9979Z"
      fill="url(#paint0_linear_666_11005)"
    />
    <path
      d="M13.3132 14.3319L12.789 17.0133C12.3359 19.331 10.2556 20.9383 7.96675 20.8789C7.84028 20.8756 7.27986 19.5392 7.27986 19.5392C6.90321 19.4656 5.91467 20.4992 5.58 20.3457C3.61701 19.4466 2.43888 17.2811 2.87025 15.0745L3.39443 12.3931C3.85652 10.0293 6.01183 8.40492 8.3543 8.53346C8.3543 8.53346 10.6344 9.07317 10.759 9.13583C12.6284 10.0755 13.7329 12.1846 13.3132 14.3319Z"
      fill="#A06564"
    />
    <path
      d="M13.3112 14.3321L12.787 17.0136C12.334 19.3309 10.2537 20.9385 7.96483 20.8792C7.96339 20.8789 7.96186 20.8791 7.96043 20.8788C7.8268 20.8472 7.27799 19.5395 7.27799 19.5395C7.23918 19.5319 7.19361 19.5359 7.14273 19.5494L9.25391 8.74976C9.90612 8.90759 10.6858 9.1003 10.7574 9.13618C10.7633 9.13931 10.7692 9.14197 10.7751 9.1451C12.6333 10.0889 13.7298 12.1912 13.3112 14.3321Z"
      fill="#875959"
    />
    <path
      d="M9.60928 18.0145L7.96528 20.8789C7.75918 20.877 7.31032 20.8192 7.17279 20.7923L6.6476 20.6896C6.27096 20.616 5.91319 20.4993 5.57853 20.3458L6.74896 17.5957C7.09787 16.7754 7.08885 15.8468 6.72336 15.0341L6.52021 14.582C6.11362 13.6767 6.15036 12.6337 6.61978 11.7593L8.35281 8.5336C8.56994 8.5452 8.78905 8.57211 9.00804 8.61491L9.53323 8.71757C9.82553 8.77471 10.3917 8.91205 10.7753 9.14502L9.43552 11.8689C9.39631 11.9906 9.36486 12.1133 9.3406 12.2374C9.18913 13.0122 9.32891 13.8216 9.7421 14.5048C10.4023 15.5965 10.35 16.9763 9.60928 18.0145Z"
      fill="#684B52"
    />
    <path
      d="M9.6089 18.0145L7.96498 20.8785C7.96345 20.8787 7.96201 20.8785 7.96048 20.8787C7.75249 20.8763 7.30899 20.819 7.17242 20.7923L6.90983 20.7409L9.27026 8.66626L9.53286 8.71759C9.82516 8.77472 10.3914 8.91177 10.7751 9.14498L9.4352 11.869C9.39599 11.9906 9.36454 12.1133 9.34028 12.2374C9.18881 13.0123 9.32859 13.8216 9.74177 14.5049C10.402 15.5965 10.3496 16.9763 9.6089 18.0145Z"
      fill="#563F46"
    />
    <path
      d="M22.1288 9.92292L21.6047 12.6044C21.1516 14.922 19.0714 16.5294 16.7824 16.47C16.656 16.4667 16.0955 15.1303 16.0955 15.1303C15.7189 15.0567 14.7303 16.0903 14.3957 15.9368C12.4327 15.0377 11.2546 12.8722 11.6859 10.6656L12.2101 7.98412C12.6722 5.62035 14.8275 3.99598 17.17 4.12453C17.17 4.12453 19.45 4.66422 19.5746 4.7269C21.444 5.6666 22.5486 7.77564 22.1288 9.92292Z"
      fill="#A06564"
    />
    <path
      d="M22.1271 9.92318L21.603 12.6046C21.15 14.9219 19.0696 16.5296 16.7807 16.4702C16.7793 16.4699 16.7778 16.4701 16.7763 16.4699C16.6427 16.4383 16.0939 15.1306 16.0939 15.1306C16.0551 15.123 16.0095 15.127 15.9586 15.1404L18.0698 4.34082C18.722 4.49865 19.5018 4.69136 19.5733 4.72724C19.5792 4.73038 19.5851 4.73303 19.591 4.73617C21.4492 5.67996 22.5457 7.78223 22.1271 9.92318Z"
      fill="#875959"
    />
    <path
      d="M18.4252 13.6056L16.7812 16.47C16.5751 16.4681 16.1262 16.4102 15.9887 16.3833L15.4635 16.2807C15.0869 16.2071 14.7291 16.0904 14.3944 15.9369L15.5649 13.1867C15.9138 12.3664 15.9048 11.4379 15.5393 10.6252L15.3361 10.173C14.9295 9.26774 14.9663 8.22474 15.4357 7.35038L17.1687 4.12466C17.3859 4.13626 17.605 4.16317 17.824 4.20598L18.3491 4.30864C18.6415 4.36577 19.2077 4.50311 19.5913 4.7361L18.2515 7.46001C18.2123 7.58169 18.1808 7.70439 18.1566 7.8285C18.0051 8.60332 18.1449 9.41269 18.5581 10.0959C19.2183 11.1876 19.166 12.5673 18.4252 13.6056Z"
      fill="#684B52"
    />
    <path
      d="M18.4248 13.6056L16.7809 16.4696C16.7794 16.4698 16.7779 16.4695 16.7764 16.4697C16.5684 16.4674 16.1249 16.41 15.9883 16.3833L15.7257 16.332L18.0862 4.25732L18.3488 4.30865C18.6411 4.36579 19.2073 4.50283 19.591 4.73604L18.2511 7.46002C18.2119 7.5817 18.1805 7.7044 18.1562 7.82851C18.0047 8.60333 18.1445 9.4127 18.5577 10.0959C19.2179 11.1876 19.1656 12.5673 18.4248 13.6056Z"
      fill="#563F46"
    />
    <defs>
      <linearGradient
        id="paint0_linear_666_11005"
        x1="12.4995"
        y1="0"
        x2="12.4995"
        y2="24.9979"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF8EF" />
        <stop offset="1" stopColor="#E4BE98" />
      </linearGradient>
    </defs>
  </svg>
)
