import { Dispatch, useReducer } from 'react'
import { GameState } from '../model'
import { INITIAL_GAME_STATE } from '../util.ts/gameState'
import {
  gameStateReducer,
  GameStateReducer,
  GameStateActionI,
} from '../reducers/gameStateReducer'

type UseGameStateReturn = [GameState, Dispatch<GameStateActionI>]

export const useGameState = (): UseGameStateReturn => {
  const [gameState, dispatch] = useReducer<GameStateReducer>(
    gameStateReducer,
    INITIAL_GAME_STATE
  )
  return [gameState, dispatch]
}
