import React, { useContext } from 'react'
import { i18n } from '@lingui/core'

import { GameStateContext } from '../contexts'
import { CheckBoxButton } from '../facets'
import { DecisionOption } from '../model'

export const EventDecision = () => {
  const { gameState, event, dispatch } = useContext(GameStateContext)

  const resolveEvent = (decision: DecisionOption) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: 'resolveEvent',
        event,
        decision,
      })
      resolve()
    })
  }

  const decision = gameState.pastDecisions.find(
    (d) => d.eventId === event.id
  )?.decision

  if (event.noDecision) {
    return null
  }

  return (
    <>
      <span>
        <CheckBoxButton
          onClick={() => {
            if (!decision) {
              window && window.scrollTo({ top: 0 })
              resolveEvent(DecisionOption.A)
            }
          }}
          variant={
            decision === 'A'
              ? 'selected'
              : decision === 'B'
              ? 'inactive'
              : undefined
          }
        >
          {event.optionA[i18n.locale as 'de']}
        </CheckBoxButton>
      </span>
      <span>
        <CheckBoxButton
          onClick={() => {
            if (!decision) {
              window && window.scrollTo({ top: 0 })
              resolveEvent(DecisionOption.B)
            }
          }}
          variant={
            decision === 'B'
              ? 'selected'
              : decision === 'A'
              ? 'inactive'
              : undefined
          }
        >
          {event.optionB[i18n.locale as 'de']}
        </CheckBoxButton>
      </span>
    </>
  )
}
