import { Color } from '../model/Color'
import { Sector } from '../model/Sector'
import { getTranslated } from './helpers'

const sectorsData = require('../static/sectors.json')

export const SECTORS: Sector[] = sectorsData.map(
  (i: { [key: string]: string }): Sector => {
    return {
      id: i.id as string,
      name: getTranslated(i, 'name'),
      desc: getTranslated(i, 'desc'),
      color: i.color as Color,
      illustration: i.illustration,
      illustrationSmall: i.illustrationSmall,
    }
  }
)
